$(function(){
  $('.slider').slick({
    autoplay: true,
    autoplaySpeed: 6000,
    speed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    accessibility: true,
    arrows: false,
    fade: true
  });
});

